

:root {
  --space-default: 1.875;
}

/* MUI FONTS BASE OVERRIDE */

:root .Toastify__progress-bar {
  opacity: 0 !important;
}

:root .MuiFormHelperText-root {
  margin: .5rem 0 0;
}

:root .MuiMenuItem-root.Mui-selected {
  background: transparent;
  opacity: 1;
  font-weight: bold;
} 

:root .MuiMenuItem-root.Mui-selected.userTypes {
  background: #001AFF;
  color: white;
  font-weight: normal;
  border-radius: 4px;
} 

:root .MuiBackdrop-root {
  background-color: rgba(0, 3, 31, 0.5) ;
}
:root .MuiPopover-root .MuiBackdrop-root {
  background-color: transparent;
}

:root .MuiButtonBase-root.MuiMenuItem-root:hover {
  background-color: #001AFF;
  color: white;
  margin: 0 4px;
  border-radius: 4px;
}


:root .MuiButtonBase-root.MuiMenuItem-root.Mui-selected:hover {
  background-color: #f1f1f1;
  color: black;
  margin: 0;
  border-radius: 0;
}

:root .MuiTypography-small {
  font-size: .75rem;
}

:root .MuiButton-root {
  font-family: "magma medium" !important;
  font-size: .875rem ;
  box-shadow: none;
  /* border: 1px solid #001AFF; */
}

:root .MuiButton-sizeLarge {
  padding: 14px 24px;
  line-height: 17.71px;
}

:root .MuiButton-root:hover{
  box-shadow: none;
}

:root .MuiButton-sizeLarge {
  min-height: 46px;
  min-width: fit-content;
}

:root  .MuiTypography-body {
  font-weight: 400;
  font-size: 14px;  
  font-family: "magma regular";
}
:root .MuiTypography-body1 {
  font-weight: 400;
  font-size: 14px;
  font-family: "magma regular" !important;
}
:root .MuiTypography-body2 {
  font-size: .875rem;
}
:root .MuiButton-outlined.MuiButton-sizeLarge  {
  /* border-color: #D9D9D9; */
  box-shadow: inset 0 0 0 1px #D9D9D9;
  border: none;
}
:root .MuiButton-outlinedError {
  border-color: #D21A1A;
}

:root .MuiSwitch-track {
   background-color: #001AFF;
}

:root .MuiPaper-elevation.MuiTableContainer-root {
  box-shadow: none;
}

:root tbody .MuiTableRow-root td,
:root tbody .MuiTableRow-root th {
  border: none;
}

:root tbody .MuiTableRow-root .MuiTableCell-root {
  padding: 1rem 0rem 0rem 0rem;
}

:root .MuiTableRow-root th {
  padding: 0rem 0rem .5rem 0rem;
}

:root .MuiTableRow-root thead .MuiTableCell-root {
  border-bottom: 1px solid #EBECEF;
}

:root .MuiSlider-markLabel {
  width: 60px;
  display: block;
  white-space: pre-wrap;
  text-align: center;
}
 
:root #search-stakeholders {
  height: 16px;
}

.MuiAutocomplete-root .MuiInput-root{
  flex-direction: column-reverse;
  width: 100%;
}



:root .MuiAutocomplete-root .MuiAutocomplete-tag {
  margin-right: auto;
}

:root .MuiAutocomplete-root .MuiChip-root {
  background-color: #001AFF !important;
  border-radius: 5px !important;
  margin-right: 0.2rem;
  color: white;
}

:root .MuiAutocomplete-root .MuiChip-root svg {
  fill: white;
}

:root .chip-small {
 
}

:root .chip-small span {
  font-size: .68rem;
}

:root input {
  font-size: 14px;
  height: 18px;
}
/* 


:root .MuiAutocomplete-root.MuiOutlinedInput-root.MuiAutocomplete-input,
:root .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 0;
}


:root .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0 !important;
}

:root fieldset {
  height: 2.375rem;
} */

:root .MuiInputBase-root-MuiOutlinedInput-root {
  height: 38px;
}
:root .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
  height: 24px;
  padding: 15px 14px 10px;
}

:root .searchInputBuildings fieldset {
  height: 38px;
}

 
:root .search-inputs fieldset {
  height: 45px;
}

:root .MuiFormControl-root.MuiTextField-root{
  height: 38px;
}

.messenger .MuiDrawer-docked .MuiDrawer-paper {
  overflow-x: initial;
}


 .caroussel > div + div  {
  opacity: 0;
 }

 .caroussel > div + div:last-of-type  {
   opacity: 1; 
 }


 ::-webkit-input-placeholder { /* Edge */
  color: #D9D9D9;
  font-size: 14px;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #D9D9D9;
  font-size: 14px;
}

::placeholder {
  color: #D9D9D9;
  font-size: 14px;
}

:root .MuiInputBase-root.MuiAutocomplete-root.MuiOutlinedInput-root {
  padding: 10px !important;
}

 

