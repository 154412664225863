
html,
body {
  padding: 0;
  margin: 0;
  height: 100vh;
}

.layout-hidden .Layout {
  min-width: 100% !important;
}

.layout-hidden .messenger,
.layout-hidden .Navigation, 
.layout-hidden .Buildings {
  display: none !important;
}

.layout-hidden .Container {
  margin: 0 !important;
  padding: 0 !important;
  
}

body {
  background-color: rgb(252, 252, 253) !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none !important;
  cursor: pointer;
}

/* a span {
  font-size: .8rem;
} */

:root .MuiChip-root {
  font-family: "magma light";
  height: 23px;
  margin-top: 2px;
  padding: 0;
}

:root .MuiChip-root span{
  font-size: .75rem;
}

:root tbody .MuiTableRow-root .MuiTableCell-root.activitytablecell{
  padding: 14px 16px;
}

* {
  box-sizing: border-box;
}

.zoom {
  height: 400px;
  width: 90%;
  overflow: hidden;
  min-height: max-content;
}

.zoom .building-img {
  width: 102%;
  transition: transform .2s;
  object-fit: cover;
}

.zoom:hover .building-img {
  transform: scale(1.2);
}


.zoom:hover .building-img.lock {
  width: 100%;
  transform: scale(1);
}

@font-face {
  font-family: "magma light";
  src: url("../fonts/BeVietnamPro-Light.woff");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "magma thin";
  src: url("../fonts/BeVietnamPro-Thin.woff");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "magma regular";
  src: url("../fonts/BeVietnamPro-Regular.woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "magma medium";
  src: url("../fonts/BeVietnamPro-Medium.woff");
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "magma bold";
  src: url("../fonts/BeVietnamPro-Bold.woff");
  font-style: bold;
  font-weight: 700;
  font-display: swap;
}

:root label {
  line-height: 1.107;
}

ul {
  list-style: none;
  padding: 0;
}

:root body {
  font-family: 'magma regular', -apple-system, BlinkMacSystemFont, Helvetica, Helvetica Neue, sans-serif;
}



.Carousel-indicators-2 {
  margin-top: -2rem !important;
}

.progress {
  background-color: #F7F7F8;
  border-radius: 4px;
  position: relative;
  margin: 0;
  height: 25px;
  min-width: 130px;
}

.progress-level {
  background-color: #001AFF25;
  border-radius: 2px;
  height: 6px!important;
  margin-top: 5px!important;
}

.progress span {
  mix-blend-mode: difference;
  font-size: 11px;
  font-weight: 400;
}

.progress-done {
  background: #001AFF;
  border-radius: 4px;
  color: #F7F7F8;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}

.progress-done.progress-green {
  background: #1AD24E;
}

/* // Scrollbars style */

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 5px;
  background: transparent !important;
}



/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: transparent !important; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.448);
  border-radius: 10px;
}




/* _________MAP STYLES_________ */

.main__wrapper {
  /* display: flex;
  justify-content: flex-start; */
}

.searchMap {
  /*position: relative;
  margin-bottom: 2rem;*/
}

.map {
  width: 100%!important;
  height: 300px !important;
  margin-top: 24px;
  border-radius: 4px;
  overflow: hidden;
  z-index: 0;
}


.map__container {
  width: 100% ;
  height: 100%;
}

.map__edit {
  position: absolute;
  bottom: 20px;
  right: 16px;
  width: 200px;
  height: 128px;
  border-radius: 4px;
  overflow: hidden;
}


/* map search */
.map__searcher {
  width: 100%;
  display: block;
  box-sizing: border-box;
}

.map__results_list {
  padding: 0;
  margin: 0;
  position: absolute;
  margin-bottom: 2rem;
  top: 40;
  z-index: 2;
  max-height: 200px;
  width: 100%;
  left: 10px;
  overflow: scroll;
  border-radius: 14px;
}
 
.map__results_item {
  list-style: none;
  background: rgba(255, 255, 255, 0.8);
  padding:  10px;
  cursor: pointer;
  z-index: 1;
}

.map__results_item:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
}

/* Numbered lists like 1, 1.1, 2.2.1... */
ol:not([type]) li {display: block;} /* hide original list counter */
ol:not([type]) > li {counter-increment: item; position: relative;} /* increment counter for lists without type-a class */
ol:not([type]) > li:before {content: counters(item, ".") ". "; position: absolute; margin-right: 100%; right: 10px; font-weight: bold;} /* print counter for lists without type-a class */

/* Reset counter for nested lists */
ol:not([type]) ol:not([type]) {counter-reset: item;}

/* Increment counter for nested list items */
ol:not([type]) ol:not([type]) > li {counter-increment: item;}

/* Print counter for nested list items */
ol:not([type]) ol:not([type]) > li:before {
  content: counters(item, ".") ". ";
  position: absolute;
  margin-right: 100%;
  right: 10px;
  font-weight: bold;
}

ol:not([type]) ol:not([type]) ol:not([type]) ol:not([type]) {
  padding-inline-start: 63px;
}

ul > li:before {
  content: '\2022'; /* Unicode character for disc */
  margin-right: 10px;
  font-weight: bold;
}

/* Styling for ordered lists with class "type-a" */
ol[type=a] {list-style-type: lower-alpha; padding-left: 26px;} /* set list style to lower-alpha for type-a lists */
ol[type=a] li {display:block; margin-bottom: 10px;} /* hide original list counter for type-a lists */
ol[type=a] > li:first-child {counter-reset: item-a;} /* reset counter for type-a lists */
ol[type=a] > li {counter-increment: item-a; position: relative;} /* increment counter for type-a lists */
ol[type=a] > li:before {content: counter(item-a, lower-alpha) ") "; position: absolute; margin-right: 100%; right: 10px; font-weight: bold;} /* print counter for type-a lists */

/* Styling for ordered lists with class "type-i" */
ol[type=i] {list-style-type: lower-roman; padding-left: 26px;} /* set list style to lower-roman for type-i lists */
ol[type=i] li {display:block; margin-bottom: 10px;} /* hide original list counter for type-i lists */
ol[type=i] > li:first-child {counter-reset: item-a;} /* reset counter for type-i lists */
ol[type=i] > li {counter-increment: item-a; position: relative;} /* increment counter for type-i lists */
ol[type=i] > li:before {content: "(" counter(item-a, lower-roman) ") "; position: absolute; margin-right: 100%; right: 10px; font-weight: bold;} /* print counter for type-i lists */

ol li div.title-2 {
  font-weight: bold;
  margin-bottom: 15px;
}

.styledOrderList li{
  font-weight: 400;
  font-size: 14px;
  font-family: "magma regular";
  text-align: justify;
  margin-bottom: 1rem;
}

.styledOrderList li a {
  color: blue;
}

ul.styledUnorderedList {
  list-style: none;
  padding: 0;
}

ul.styledUnorderedList > li {
  position: relative;
  display: flex;
}

ul.styledUnorderedList > li::before {
  position: absolute;
}

ul.styledUnorderedList > li p {
  margin: 0; /* Remove default paragraph margin */
  margin-left: 2em; /* Adjust the indentation as needed */
}

/* toaster */

.Toastify__toast-theme--light.Toastify__toast--default {
  background-color: white;
}
.Toastify__toast-theme--light.Toastify__toast--info {
  background-color: #F7F7F8;
  color: #00031F;
}
.Toastify__toast-theme--light.Toastify__toast--success {
  background-color: #001AFF;
  color: #F7F7F8;
}
.Toastify__toast-theme--light.Toastify__toast--warning {
  background-color: #FFA800;
  color: #F7F7F8;
}
.Toastify__toast-theme--light.Toastify__toast--error {
  background-color: #D21A1A;
  color: #F7F7F8;
}

.Toastify__toast-icon {
  display: none;
}

.Toastify__close-button {
  margin-top: 1rem;
  opacity: 1;
  color:#F7F7F8;
}

.Toastify__toast--info .Toastify__close-button {
  color:#00031F;
}

 
.with-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}

.pg-viewer-wrapper{
  overflow-x: hidden;
}

.pdf-viewer {
  padding: 10px;
}

.pdf-canvas {
  padding: 5px;
}

.UploadedFiles .UploadedFilesImg {
  width: 40px;
  height: 40px;
  margin: 12px 0 12px 12px;
  object-fit: cover;
}

 
.link-disabled {
  color: #B4B5C1;
}

.link-disabled svg {
  fill: #B4B5C1;
}


.link-disabled:hover {
  background-color: transparent;
  pointer-events: none;
}

img.userType{
  filter: brightness(0.2)
}

img.userType.selected{
  filter: brightness(0.7)
}

.icon-error{
  filter: invert(24%) sepia(59%) saturate(3047%) hue-rotate(343deg) brightness(100%) contrast(95%);
}

.upload-btn:hover > svg {
  filter: invert(1);
}

